.submit-input {
  width: 100%;
  margin: 12px auto;
  color: var(--white);
  background-color: var(--main);
  padding: 12px 16px;
  font-size: 16px;
  line-height: 1.5;
  height: 48px;
  outline: none;
  border: none;
  transition: all 0.5s ease-in-out;
  position: relative;
  border-radius: 3px;
}
.submit-input:hover {
  background-color: var(--main-hover);
}
.submit-input.disabled {
  cursor: not-allowed;
  background-color: var(--background-placeholder);
  color: var(--black);
}
