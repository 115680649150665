.header {
  height: 110px;
  width: 100%;
  position: sticky;
  top: 0;
  left: 0;
  z-index: 5;
  box-shadow: 0 4px 6px -3px var(--black);
  transition: all 300ms ease-out;
}

.header.mini {
  height: 134px;
}

.header.mini .roadshow-item {
  height: 90px;
}

.header.mini .roadshow-item,
.header.mini .roadshow-item--image {
  border-radius: 0;
}

.header.roadshow-header {
  height: 144px;
}

.roadshow-header .roadshow {
  margin: 0;
  border-radius: 0;
  height: 100px;
  transition: all 300ms ease-out;
}
.roadshow-header .roadshow .roadshow-item--image {
  border-radius: 0;
}
