.list {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  box-sizing: border-box;
  padding: 15px;
}
.list-item {
  scroll-snap-align: center;
  position: relative;
  list-style: none;
  display: block;
  width: 100%;
}
.list-item:not(:last-child) {
  margin-bottom: 15px;
}

@media (max-width: 320px) {
  .list {
    padding: 10px;
  }
  .list-item:not(:last-child) {
    margin-bottom: 10px;
  }
}
