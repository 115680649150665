.settings {
  height: calc(100vh - 30px);
  padding: 15px;
  background: var(--list-view-primary);
  background-image: var(--page-gradient);
  position: relative;
}
.field-area {
  width: 100%;
}
.field-area--title {
  display: block;
  text-align: center;
  margin: 10px 0;
  width: 100%;
  color: var(--black);
}
.field-area--options {
  padding: 10px 0;
  width: 100%;
}
.field-area--label {
  display: block;
  padding: 10px;
  text-align: center;
  width: calc(100% - 20px);
  transition: all 0.5s ease-in-out;
  border: 1px solid var(--border);
}
.field-area--label.checked {
  background-color: var(--main);
  color: var(--white);
}
.field-area--label input {
  display: none;
}
.radio-picker-inline {
  display: inline-block;
  padding: 10px;
  text-align: center;
  width: calc(50% - 22px);
  transition: all 0.5s ease-in-out;
  border: 1px solid var(--border);
}
.radio-picker-inline.checked {
  background-color: var(--main);
  color: var(--white);
}
.radio-picker-inline input {
  display: none;
}
.page-buttons {
  width: calc(100% - 30px);
  position: absolute;
  bottom: 15px;
  left: 15px;
}
