.appointment-info {
  padding: 10px 15px;
  /* margin-bottom: 46px; */
  z-index: 4;
  color: var(--black);
  background-color: var(--white);
}
.appointment-info > div {
  white-space: pre-line;
  padding: 0;
  margin: 0;
}
.appointment-info > div p {
  padding: 0;
  margin: 0;
}
