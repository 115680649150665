.date-pick {
  box-shadow: 0 1px 5px -1px var(--black);
  z-index: 10;
  background-color: var(--white);
  position: relative;
}
.date-pick--container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(45px, 1fr));
  grid-auto-flow: column;
  overflow: auto;
  grid-template-rows: 1fr;
  grid-row: 1;
}
.day {
  display: inline-block;
  padding: 10px;
  border: 1px solid var(--border);
  color: var(--black);
  text-align: center;
  transition: all 0.45s ease-in;
  outline: none;
}
.day.current {
  display: inline-block;
  border: 1px solid var(--background-card);
  color: var(--white);
  background: var(--gray);
  transition: all 0.45s ease-in;
}
