.edit-appointment {
  text-align: center;
  position: relative;
  height: 100%;
}
.edit-fields h4 {
  margin: 0;
  padding: 12px 0;
}
.edit-fields input {
  width: calc(100% - 36px);
  margin: 0 auto;
  border: 2px solid var(--list-view-primary);
  padding: 12px 16px;
  font-size: 16px;
  line-height: 1.5;
  outline: none;
  -webkit-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  border-radius: 3px;
}
.btn-close {
  position: absolute;
  top: -5px;
  right: -5px;
  padding: 5px 10px;
  border: none;
  background: transparent;
}
/*.edit-fields select,*/
/*.edit-fields input {*/
/*  box-sizing: border-box;*/
/*  background-color: var(--white);*/
/*  padding: 8px 10px;*/
/*  border: none;*/
/*  width: 100%;*/
/*}*/
/*.edit-fields select:focus,*/
/*.edit-fields input:focus {*/
/*  transition: background-color 0.3s ease;*/
/*  background-color: var(--list-view-primary);*/
/*}*/
/*.buttons-container {*/
/*  margin: 8px 0;*/
/*}*/
/*.buttons-container button {*/
/*  border: none;*/
/*  background-color: var(--white);*/
/*  color: var(--main);*/
/*  padding: 4px 10px;*/
/*}*/
/*.picked-date {*/
/*  display: block;*/
/*  margin: 4px 0;*/
/*  text-align: center;*/
/*  text-transform: uppercase;*/
/*  color: var(--black);*/
/*}*/

.autocomplete-root {
  position: relative;
}

.autocomplete-dropdown-container {
  z-index: 130;
  position: absolute;
  top: 52px;
  left: 0;
  right: 0;
  max-height: 70px !important;
  overflow-y: auto;
  font-size: 14px;
  line-height: 1.25;
  text-align: left;
  background-color: var(--white);
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.15);
  border: 1px solid var(--border);
  border-radius: 4px;
  box-sizing: border-box;
}

.suggestion-loading {
  padding: 13px 16px;
}

.suggestion-item {
  padding: 5px 16px;
  background-color: var(--white);
}

.suggestion-item:first-child {
  margin-top: 8px;
}

.suggestion-item:last-child {
  margin-bottom: 8px;
}

.suggestion-item.active {
  background-color: #f7f7f7;
}