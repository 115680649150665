.login-form {
  width: 100%;
}
.login-form input {
  width: calc(100% - 36px);
  margin: 0 auto;
  border: none;
  padding: 12px 16px;
  font-size: 16px;
  line-height: 1.5;
  outline: none;
  transition: all 0.5s ease-in-out;
  border-radius: 3px;
}
.login-form .text-input {
  margin-bottom: 12px;
  border-bottom: 1px solid #ccc;
  border: 2px solid var(--list-view-primary);
}
.login-form .text-input.success {
  border: 2px solid var(--success);
  transition: all 0.5s ease-in-out;
}
.login-form .text-input.error {
  border: 2px solid var(--alert);
  transition: all 0.5s ease-in-out;
}
