.popup--page {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #00000070;
  z-index: 10;
  transition: all 0.5s ease-in-out;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.popup {
  width: 80%;
  max-width: 420px;
  padding: 10px;
  background-color: var(--white);
  border-radius: 6px;
  transition: all 0.5s ease-in-out;
}
.popup h4 {
  margin: 0;
  line-height: 1.5;
  text-align: center;
}
.popup hr {
  margin: 10px 0;
  border: 0.5px solid var(--background-card);
}
.popup p {
  margin: 0;
  line-height: 1.25;
  text-align: center;
}
.button-group {
  width: 100%;
  margin-top: 10px;
  display: flex;
  justify-content: center;
  flex-wrap: nowrap;
}
.button-group button {
  width: 50%;
  padding: 10px 12px;
  font-size: 14px;
  background-color: var(--white);
  border: 1px solid var(--background-card);
  transition: all 0.25s ease-in-out;
}
.button-group button.active {
  transition: all 0.25s ease-in-out;
  color: var(--black);
  background-color: var(--background-card);
  border: 1px solid var(--white);
}
.popup-enter .popup {
  opacity: 0;
  transform: scale(0.5);
}
.popup-enter-active .popup {
  opacity: 1;
  transform: scale(1);
  transition: transform 200ms ease-in;
}
.popup-exit .popup {
  opacity: 1;
  transform: scale(1);
}
.popup-exit-active .popup {
  opacity: 0;
  transform: scale(0.5);
}
