.modal {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 125;
  background-color: var(--background-modal);
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
.modal-wrapper {
  width: 90%;
  height: auto;
  background-color: var(--white);
  border-radius: 5px;
  padding: 10px;
  box-sizing: border-box;
}
