.button {
  width: 100%;
  background-color: var(--main);
  color: var(--white);
  border: none;
  padding: 12px 16px;
  font-size: 16px;
}
.button:not(:first-child) {
  margin-top: 12px;
}
