.person-info {
  width: calc(100% - 30px);
  padding: 10px 15px;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  background-color: var(--white);
  color: var(--black);
  border-bottom: 1px solid var(--border);
}
.person-info--image {
  height: 90px;
  width: 90px;
}
.person-info--image > div {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  position: relative;
  outline: none;
  z-index: 999;
}
.person-info--image > div.active {
  background-color: var(--black);
  width: 100%;
  max-width: 520px;
  border-radius: 0;
  height: calc(100vh - 126px);
  position: absolute;
  left: 0;
  top: 0;
}
.person-info--image .person-info--image-container {
  border-radius: 50%;
  width: 100%;
  height: 100%;
  max-width: 90px;
  max-height: 90px;
  background-color: var(--background-placeholder);
  background-size: cover;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  position: absolute;
  top: 0;
  left: 0;
}
.person-info--image > div.active .person-info--image-container {
  width: 70vmin;
  height: 70vmin;
  max-width: 360px;
  max-height: 360px;
  top: calc(50% - 35vmin);
  left: calc(50% - 35vmin);
  transition: all 0.3s ease-in;
}
@media (min-width: 520px) {
  .person-info--image > div.active .person-info--image-container {
    top: calc(50% - 180px);
    left: calc(50% - 180px);
  }
}
.person-info--image > div :active,
.person-info--image > div :focus {
  outline: none;
}
.person-info--details {
  width: calc(100% - 100px);
  padding-right: 15px;
  box-sizing: border-box;
}
.person-info--details h2 {
  margin: 0;
  line-height: 1.25;
  font-size: 1.25em;
  font-weight: 500;
}
.person-info--details h2.company-name {
  font-weight: 300;
  display: inline-block;
}
.person-info--details .person-info--name {
  margin: 0;
  margin-bottom: 6px;
  font-size: 1.45em;
  line-height: 1;
  display: block;
}

.person-info .action {
  position: absolute;
  top: 35px;
  right: 10px;
  width: 26px;
  height: 26px;
  z-index: 100;
}

.person-info .action .svg-inline--fa {
  width: 100%;
  height: 100%;
}

.person-info .ellipsis-dropdown {
  position: absolute;
  top: calc(100% + 12px);
  right: 0;
  width: 125px;
  z-index: 100;
}

.person-info .ellipsis-dropdown.hidden {
  display: none;
}

.person-info .ellipsis-dropdown .ellipsis-dropdown-panel {
  background: var(--white);
  border: 1px solid var(--border);
  border-radius: 3px;
  box-shadow: 0px -1px 12px 3px rgba(0, 0, 0, 0.15);
  font-size: 1.25em;
  -webkit-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}

.person-info .ellipsis-dropdown .ellipsis-dropdown-panel:after,
.person-info .ellipsis-dropdown .ellipsis-dropdown-panel:before {
  content: "";
  display: block;
  width: 0;
  height: 0;
  position: absolute;
}

.person-info .ellipsis-dropdown .ellipsis-dropdown-panel:after {
  right: 8px;
  top: -4px;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 5px solid var(--white);
}

.person-info .ellipsis-dropdown .ellipsis-dropdown-panel:before {
  right: 7px;
  top: -5px;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-bottom: 6px solid var(--border);
}

.person-info .ellipsis-dropdown .ellipsis-dropdown-panel .ellipsis-dropdown-item {
  display: block;
  width: 100%;
  padding: 12px 15px;
  box-sizing: border-box;
}