.list-view {
  padding: 0;
  margin: 0;
  height: calc(100vh - 144px);
  position: relative;
  overflow: hidden;
  z-index: 10;
}
.switch-list {
  position: absolute;
  z-index: 10;
  right: 10px;
  top: 72px;
}
.switch-list-appointments {
  border: none;
  width: 46px;
  height: 46px;
  padding: 5px;
  border-radius: 5px;
  color: var(--black);
  text-align: center;
  -webkit-transition: all 0.45s ease-in;
  transition: all 0.45s ease-in;
  outline: none;
  cursor: pointer;
  font-size: 32px;
  background-color: rgba(255, 255, 255, 0.8);
}
.switch-list-appointments:hover {
  background-color: rgba(255, 255, 255, 1);
}
.switch-list-appointments span.hide {
  display: none;
}
.switch-list-appointments span.show {
  display: inline-block;
}
.list-view .swipe-list {
  height: calc(100% - 40px);
}
.icons-group {
  height: 44px;
  display: flex;
  justify-content: space-between;
}
.icons-group .icon-link,
.icons-group .icon-link:not(:first-child) {
  max-width: 66px;
  border: none;
}
