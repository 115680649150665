.link,
.link:visited {
  text-decoration: none;
  color: inherit;
  cursor: pointer;
  display: inline-block;
}
.link .ext-link {
  width: 9px;
  height: 100%;
  vertical-align: top;
  padding-left: 4px;
  padding-top: 4px;
  display: inline-block;
}
