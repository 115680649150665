.message {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
}
.message--title {
  text-transform: uppercase;
  font-size: 16px;
}
