.list-item {
  width: calc(100% - 4px) !important;
  scroll-snap-align: center;
  list-style: none;
  border-radius: 3px;
  background: var(--background-card);
  padding: 2px;
}
.list-item.confirm {
  background: var(--success);
  background-image: var(--success-gradient);
}
.list-item.pending {
  background: var(--warning);
  background-image: var(--warning-gradient);
}
.list-item.reject {
  background: var(--alert);
  background-image: var(--alert-gradient);
}
.list-item--container {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  padding: 10px 5px;
  background-color: var(--background-card);
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  border-radius: 3px;
}
.list-item:not(:last-child) {
  margin-bottom: 10px !important;
}
.list-item--image {
  height: 70px;
  width: 70px;
}
.list-item--image div {
  border-radius: 50%;
  width: 100%;
  height: 100%;
  background-color: var(--background-placeholder);
  background-size: cover;
  background-position: 50% 50%;
  background-repeat: no-repeat;
}
.list-item--details {
  width: calc(100% - 85px);
  color: var(--black);
}
.list-item--details h4 {
  margin: 0;
  line-height: 1.25;
}
.list-item--details .company-name {
  font-weight: 300;
  margin: 2px 0;
}
.list-item--details .time {
  font-weight: 500;
}
.list-item.faded {
  opacity: 0.5;
}