.preloader-component .spinner {
  animation: rotate 2s linear infinite;
  z-index: 2;
  margin: -12px 0 0 -12px;
  width: 24px;
  height: 24px;
  position: absolute;
}
.preloader-component .spinner .path {
  stroke: #ccc;
  stroke-linecap: round;
  animation: dash 1.5s ease-in-out infinite;
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes dash {
  0% {
    stroke-dasharray: 1, 150;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -35;
  }
  100% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -124;
  }
}

.submit-input {
  width: 100%;
  margin: 12px auto;
  color: var(--white);
  background-color: var(--main);
  padding: 12px 16px;
  font-size: 16px;
  line-height: 1.5;
  height: 48px;
  outline: none;
  border: none;
  transition: all 0.5s ease-in-out;
  position: relative;
  border-radius: 3px;
}
.submit-input:hover {
  background-color: var(--main-hover);
}
.submit-input.disabled {
  cursor: not-allowed;
  background-color: var(--background-placeholder);
  color: var(--black);
}

.login-form {
  width: 100%;
}
.login-form input {
  width: calc(100% - 36px);
  margin: 0 auto;
  border: none;
  padding: 12px 16px;
  font-size: 16px;
  line-height: 1.5;
  outline: none;
  transition: all 0.5s ease-in-out;
  border-radius: 3px;
}
.login-form .text-input {
  margin-bottom: 12px;
  border-bottom: 1px solid #ccc;
  border: 2px solid var(--list-view-primary);
}
.login-form .text-input.success {
  border: 2px solid var(--success);
  transition: all 0.5s ease-in-out;
}
.login-form .text-input.error {
  border: 2px solid var(--alert);
  transition: all 0.5s ease-in-out;
}

.login-page {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding: 0 5%;
  box-sizing: border-box;
}
.login-page--logo {
  width: 45%;
  max-width: 200px;
  margin: 0 auto;
}
.social-container {
  width: 100%;
}
.social-container .social-login {
  width: 100%;
  margin: 0 auto 12px;
  background-color: var(--background-placeholder);
  padding: 8px 16px;
  border: none;
  transition: all 0.5s ease-in-out;
  color: var(--primary);
  border-radius: 3px;
  display: inline-flex;
  justify-content: flex-start;
  align-items: center;
}
.social-container .social-login:last-child {
  margin-bottom: 0;
}
.social-login > span {
  font-size: 16px;
  line-height: 1.5;
  padding: 0;
}
.social-login .fa-google {
  color: var(--white);
}
.social-login .fa-linkedin {
  color: #0077b5;
}
.social-login .svg-inline--fa {
  width: 32px;
  height: 32px;
  margin-right: 10px;
}

.button {
  width: 100%;
  background-color: var(--main);
  color: var(--white);
  border: none;
  padding: 12px 16px;
  font-size: 16px;
}
.button:not(:first-child) {
  margin-top: 12px;
}

.settings {
  height: calc(100vh - 30px);
  padding: 15px;
  background: var(--list-view-primary);
  background-image: var(--page-gradient);
  position: relative;
}
.field-area {
  width: 100%;
}
.field-area--title {
  display: block;
  text-align: center;
  margin: 10px 0;
  width: 100%;
  color: var(--black);
}
.field-area--options {
  padding: 10px 0;
  width: 100%;
}
.field-area--label {
  display: block;
  padding: 10px;
  text-align: center;
  width: calc(100% - 20px);
  transition: all 0.5s ease-in-out;
  border: 1px solid var(--border);
}
.field-area--label.checked {
  background-color: var(--main);
  color: var(--white);
}
.field-area--label input {
  display: none;
}
.radio-picker-inline {
  display: inline-block;
  padding: 10px;
  text-align: center;
  width: calc(50% - 22px);
  transition: all 0.5s ease-in-out;
  border: 1px solid var(--border);
}
.radio-picker-inline.checked {
  background-color: var(--main);
  color: var(--white);
}
.radio-picker-inline input {
  display: none;
}
.page-buttons {
  width: calc(100% - 30px);
  position: absolute;
  bottom: 15px;
  left: 15px;
}

.page {
  overflow-y: auto;
  overscroll-behavior-y: auto;
  width: 100%;
  height: 100%;
  position: relative;
  box-sizing: border-box;
  background: var(--list-view-primary);
  background-image: var(--page-gradient);
  transition: opacity, transform, background-color 300ms linear;
}
section {
  position: relative;
  z-index: 2;
  transition: all 300ms ease-out;
  background-color: var(--list-view-primary);
  background-image: var(--page-gradient);
}
footer {
  position: -webkit-sticky;
  position: sticky;
  bottom: 0;
  left: 0;
  height: 44px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap;
  background-color: var(--white);
  border-top: 1px solid var(--border);
  z-index: 5;
}
.page-enter {
  opacity: 0;
  transform: translateX(-100%);
}
.page-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: opacity, transform 300ms linear;
}
.page-exit {
  opacity: 1;
  transform: translateX(-100%);
}
.page-exit-active {
  opacity: 0;
  transform: translateX(0);
  transition: opacity, transform 300ms linear;
}

.list {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  box-sizing: border-box;
  padding: 15px;
}
.list-item {
  scroll-snap-align: center;
  position: relative;
  list-style: none;
  display: block;
  width: 100%;
}
.list-item:not(:last-child) {
  margin-bottom: 15px;
}

@media (max-width: 320px) {
  .list {
    padding: 10px;
  }
  .list-item:not(:last-child) {
    margin-bottom: 10px;
  }
}

.roadshow-item {
  padding: 10px;
  width: 100%;
  border-radius: 5px;
  height: 110px;
  position: relative;
  box-sizing: border-box;
  display: block;
  text-decoration: none;
}
.roadshow-item--image {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 5px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  z-index: 1;
}
.roadshow-item--title-placeholder {
  width: 100%;
  height: 100%;
  z-index: 2;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.roadshow-item--title {
  margin: 0;
  margin-right: auto;
  padding: 2px 6px;
  z-index: 2;
  line-height: 1.5;
  font-size: 1.1em;
  color: var(--black);
  display: inline-block;
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 5px;
}
.roadshow-item--title:not(:last-child) {
  margin-bottom: 5px;
}

.message {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
}
.message--title {
  text-transform: uppercase;
  font-size: 16px;
}

.roadshow-list,
.roadshow-page {
  height: 100vh;
}

.file {
  --file-size: 60px;
  height: var(--file-size);
  width: 100%;
  position: relative;
  color: var(--black);
  border-radius: 4px;
  border: 1px solid var(--border);
  overflow: hidden;
  background-color: var(--border);
}
.file--container {
  height: 100%;
  width: 100%;
  position: absolute;
  z-index: 10;
  outline: none;
}
.file--wrapper,
.file--wrapper:visited {
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
  align-items: center;
  width: 100%;
  height: 100%;
  text-decoration: none;
  text-align: left;
  outline: none;
  color: var(--black);
  background-color: var(--white);
}
a.file--wrapper {
  cursor: pointer;
}
.file--view {
  display: block;
  width: 24%;
  height: var(--file-size);
  margin: 0;
  padding: 2px;
  box-sizing: border-box;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}
.file--view .svg-inline--fa {
  display: block;
  width: 100%;
  height: 100%;
}
.file--name {
  display: block;
  padding: 0 10px;
  box-sizing: border-box;
  width: calc(100% - 24% - 10px);
  margin: 0;
  word-break: break-word;
  -webkit-user-select: none;
          user-select: none;
}
.file--controls {
  position: absolute;
  top: 0;
  height: var(--file-size);
  width: var(--file-size);
  padding: 12px 0;
  box-sizing: border-box;
  cursor: pointer;
  z-index: 9;
  color: var(--black);
  outline: none;
}
.file--controls.delete {
  right: 5px;
}
.file--controls.upload {
  left: 5px;
}
.file--controls .svg-inline--fa {
  width: 100%;
  height: 100%;
}

.header {
  height: 110px;
  width: 100%;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  left: 0;
  z-index: 5;
  box-shadow: 0 4px 6px -3px var(--black);
  transition: all 300ms ease-out;
}

.header.mini {
  height: 134px;
}

.header.mini .roadshow-item {
  height: 90px;
}

.header.mini .roadshow-item,
.header.mini .roadshow-item--image {
  border-radius: 0;
}

.header.roadshow-header {
  height: 144px;
}

.roadshow-header .roadshow {
  margin: 0;
  border-radius: 0;
  height: 100px;
  transition: all 300ms ease-out;
}
.roadshow-header .roadshow .roadshow-item--image {
  border-radius: 0;
}

.preloader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: 150;
  background-color: rgba(0, 0, 0, 0.3);
}
.preloader .spinner {
  animation: rotate 2s linear infinite;
  z-index: 2;
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -50px 0 0 -50px;
  width: 100px;
  height: 100px;
}
.preloader .spinner .path {
  stroke: #4484cb;
  stroke-linecap: round;
  animation: dash 1.5s ease-in-out infinite;
}

.swipe-element {
  position: absolute;
  overscroll-behavior: none;
  width: 100%;
  height: 100%;
}

.list-item {
  width: calc(100% - 4px) !important;
  scroll-snap-align: center;
  list-style: none;
  border-radius: 3px;
  background: var(--background-card);
  padding: 2px;
}
.list-item.confirm {
  background: var(--success);
  background-image: var(--success-gradient);
}
.list-item.pending {
  background: var(--warning);
  background-image: var(--warning-gradient);
}
.list-item.reject {
  background: var(--alert);
  background-image: var(--alert-gradient);
}
.list-item--container {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  padding: 10px 5px;
  background-color: var(--background-card);
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  border-radius: 3px;
}
.list-item:not(:last-child) {
  margin-bottom: 10px !important;
}
.list-item--image {
  height: 70px;
  width: 70px;
}
.list-item--image div {
  border-radius: 50%;
  width: 100%;
  height: 100%;
  background-color: var(--background-placeholder);
  background-size: cover;
  background-position: 50% 50%;
  background-repeat: no-repeat;
}
.list-item--details {
  width: calc(100% - 85px);
  color: var(--black);
}
.list-item--details h4 {
  margin: 0;
  line-height: 1.25;
}
.list-item--details .company-name {
  font-weight: 300;
  margin: 2px 0;
}
.list-item--details .time {
  font-weight: 500;
}
.list-item.faded {
  opacity: 0.5;
}
.list {
  padding: 10px 15px;
  margin: 0;
  overflow-y: auto;
  height: calc(100% - 20px);
  background: var(--list-view-primary);
  background: var(--page-gradient);
  scroll-behavior: smooth;
  scroll-snap-type: y mandatory;
}

.list li {
  width: 100%;
  list-style: none;
}

.list li:not(:last-child) {
  margin-bottom: 10px;
}

.btn-add {
  text-align: center;
}

.btn-add:not(:last-child) {
  margin-bottom: 10px;
}

.date-pick {
  box-shadow: 0 1px 5px -1px var(--black);
  z-index: 10;
  background-color: var(--white);
  position: relative;
}
.date-pick--container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(45px, 1fr));
  grid-auto-flow: column;
  overflow: auto;
  grid-template-rows: 1fr;
  grid-row: 1;
}
.day {
  display: inline-block;
  padding: 10px;
  border: 1px solid var(--border);
  color: var(--black);
  text-align: center;
  transition: all 0.45s ease-in;
  outline: none;
}
.day.current {
  display: inline-block;
  border: 1px solid var(--background-card);
  color: var(--white);
  background: var(--gray);
  transition: all 0.45s ease-in;
}

.icon-link {
  flex-grow: 1;
  width: 100%;
  height: 100%;
  /* max-width: 60px; */
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--black);
  background-color: var(--white);
  border: none;
  outline: none;
}
.icon-link:not(:first-child).logo {
  border: none;
  max-width: 60px;
}
.icon-link .logo {
  box-sizing: border-box;
  padding: 2px;
  width: 60%;
}
.icon-link.file-picker-label {
  position: relative;
}
.file-picker {
  position: absolute;
  opacity: 0;
  width: 0.1px;
  height: 0.1px;
}
.icon-link:visited,
.icon-link:focus,
.icon-link:active {
  text-decoration: none;
  color: var(--black);
}
.icon-link:not(:first-child) {
  border-left: 1px solid var(--border);
}
.icon-link svg.svg-inline--fa {
  width: 22px;
  height: 22px;
  color: var(--black);
}
.icon-link.disabled svg.svg-inline--fa {
  color: var(--background-placeholder);
}
.icon-link.large svg.svg-inline--fa {
  width: 30px;
  height: 30px;
}
.icon-link.small svg.svg-inline--fa {
  width: 16px;
  height: 16px;
}
.icon-link .fa-phone {
  transform: scale(-1, 1);
}

.modal {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 125;
  background-color: var(--background-modal);
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
.modal-wrapper {
  width: 90%;
  height: auto;
  background-color: var(--white);
  border-radius: 5px;
  padding: 10px;
  box-sizing: border-box;
}

.edit-appointment {
  text-align: center;
  position: relative;
  height: 100%;
}
.edit-fields h4 {
  margin: 0;
  padding: 12px 0;
}
.edit-fields input {
  width: calc(100% - 36px);
  margin: 0 auto;
  border: 2px solid var(--list-view-primary);
  padding: 12px 16px;
  font-size: 16px;
  line-height: 1.5;
  outline: none;
  transition: all 0.5s ease-in-out;
  border-radius: 3px;
}
.btn-close {
  position: absolute;
  top: -5px;
  right: -5px;
  padding: 5px 10px;
  border: none;
  background: transparent;
}
/*.edit-fields select,*/
/*.edit-fields input {*/
/*  box-sizing: border-box;*/
/*  background-color: var(--white);*/
/*  padding: 8px 10px;*/
/*  border: none;*/
/*  width: 100%;*/
/*}*/
/*.edit-fields select:focus,*/
/*.edit-fields input:focus {*/
/*  transition: background-color 0.3s ease;*/
/*  background-color: var(--list-view-primary);*/
/*}*/
/*.buttons-container {*/
/*  margin: 8px 0;*/
/*}*/
/*.buttons-container button {*/
/*  border: none;*/
/*  background-color: var(--white);*/
/*  color: var(--main);*/
/*  padding: 4px 10px;*/
/*}*/
/*.picked-date {*/
/*  display: block;*/
/*  margin: 4px 0;*/
/*  text-align: center;*/
/*  text-transform: uppercase;*/
/*  color: var(--black);*/
/*}*/

.autocomplete-root {
  position: relative;
}

.autocomplete-dropdown-container {
  z-index: 130;
  position: absolute;
  top: 52px;
  left: 0;
  right: 0;
  max-height: 70px !important;
  overflow-y: auto;
  font-size: 14px;
  line-height: 1.25;
  text-align: left;
  background-color: var(--white);
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.15);
  border: 1px solid var(--border);
  border-radius: 4px;
  box-sizing: border-box;
}

.suggestion-loading {
  padding: 13px 16px;
}

.suggestion-item {
  padding: 5px 16px;
  background-color: var(--white);
}

.suggestion-item:first-child {
  margin-top: 8px;
}

.suggestion-item:last-child {
  margin-bottom: 8px;
}

.suggestion-item.active {
  background-color: #f7f7f7;
}
.edit-appointment {
  text-align: center;
  position: relative;
  height: 100%;
}
.edit-fields h4 {
  margin: 0;
  padding: 12px 0;
}
.edit-fields input {
  width: calc(100% - 36px);
  margin: 0 auto;
  border: 2px solid var(--list-view-primary);
  padding: 12px 16px;
  font-size: 16px;
  line-height: 1.5;
  outline: none;
  transition: all 0.5s ease-in-out;
  border-radius: 3px;
}
.rc-time-picker {
  width: 100%;
}
.rc-time-picker-input {
  width: 100% !important;
  height: auto !important;
}
.rc-time-picker-clear {
  top: 14px;
  right: 10px;
}
.rc-time-picker-clear-icon:after {
  font-size: 20px;
}
.rc-time-picker-panel {
  width: calc(90% - 20px);
  max-width: calc(0.9 * 520px - 20px);
}
.rc-time-picker-panel-inner {
  width: 100%;
}
.rc-time-picker-panel-select {
  width: 50%;
  max-height: 96px;
}
.hide-modal {
  position: absolute;
  top: -5px;
  right: -5px;
  padding: 5px 10px;
  border: none;
  background: transparent;
}

.edit-fields textarea {
  width: calc(100% - 36px);
  margin: 0 auto;
  border: 2px solid var(--list-view-primary);
  padding: 12px 16px;
  font-family: inherit;
  font-size: 16px;
  line-height: 1.5;
  outline: none;
  transition: all 0.5s ease-in-out;
  border-radius: 3px;
}

.edit-fields.edit-fields-person input,
.edit-fields.edit-fields-person textarea {
  margin-bottom: 12px;
}

.edit-fields.edit-fields-person input:last-child,
.edit-fields.edit-fields-person textarea:last-child {
  margin-bottom: 0;
}

.list-view {
  padding: 0;
  margin: 0;
  height: calc(100vh - 144px);
  position: relative;
  overflow: hidden;
  z-index: 10;
}
.switch-list {
  position: absolute;
  z-index: 10;
  right: 10px;
  top: 72px;
}
.switch-list-appointments {
  border: none;
  width: 46px;
  height: 46px;
  padding: 5px;
  border-radius: 5px;
  color: var(--black);
  text-align: center;
  transition: all 0.45s ease-in;
  outline: none;
  cursor: pointer;
  font-size: 32px;
  background-color: rgba(255, 255, 255, 0.8);
}
.switch-list-appointments:hover {
  background-color: rgba(255, 255, 255, 1);
}
.switch-list-appointments span.hide {
  display: none;
}
.switch-list-appointments span.show {
  display: inline-block;
}
.list-view .swipe-list {
  height: calc(100% - 40px);
}
.icons-group {
  height: 44px;
  display: flex;
  justify-content: space-between;
}
.icons-group .icon-link,
.icons-group .icon-link:not(:first-child) {
  max-width: 66px;
  border: none;
}

.popup--page {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #00000070;
  z-index: 10;
  transition: all 0.5s ease-in-out;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.popup {
  width: 80%;
  max-width: 420px;
  padding: 10px;
  background-color: var(--white);
  border-radius: 6px;
  transition: all 0.5s ease-in-out;
}
.popup h4 {
  margin: 0;
  line-height: 1.5;
  text-align: center;
}
.popup hr {
  margin: 10px 0;
  border: 0.5px solid var(--background-card);
}
.popup p {
  margin: 0;
  line-height: 1.25;
  text-align: center;
}
.button-group {
  width: 100%;
  margin-top: 10px;
  display: flex;
  justify-content: center;
  flex-wrap: nowrap;
}
.button-group button {
  width: 50%;
  padding: 10px 12px;
  font-size: 14px;
  background-color: var(--white);
  border: 1px solid var(--background-card);
  transition: all 0.25s ease-in-out;
}
.button-group button.active {
  transition: all 0.25s ease-in-out;
  color: var(--black);
  background-color: var(--background-card);
  border: 1px solid var(--white);
}
.popup-enter .popup {
  opacity: 0;
  transform: scale(0.5);
}
.popup-enter-active .popup {
  opacity: 1;
  transform: scale(1);
  transition: transform 200ms ease-in;
}
.popup-exit .popup {
  opacity: 1;
  transform: scale(1);
}
.popup-exit-active .popup {
  opacity: 0;
  transform: scale(0.5);
}

.link,
.link:visited {
  text-decoration: none;
  color: inherit;
  cursor: pointer;
  display: inline-block;
}
.link .ext-link {
  width: 9px;
  height: 100%;
  vertical-align: top;
  padding-left: 4px;
  padding-top: 4px;
  display: inline-block;
}

.person-info {
  width: calc(100% - 30px);
  padding: 10px 15px;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  background-color: var(--white);
  color: var(--black);
  border-bottom: 1px solid var(--border);
}
.person-info--image {
  height: 90px;
  width: 90px;
}
.person-info--image > div {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  position: relative;
  outline: none;
  z-index: 999;
}
.person-info--image > div.active {
  background-color: var(--black);
  width: 100%;
  max-width: 520px;
  border-radius: 0;
  height: calc(100vh - 126px);
  position: absolute;
  left: 0;
  top: 0;
}
.person-info--image .person-info--image-container {
  border-radius: 50%;
  width: 100%;
  height: 100%;
  max-width: 90px;
  max-height: 90px;
  background-color: var(--background-placeholder);
  background-size: cover;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  position: absolute;
  top: 0;
  left: 0;
}
.person-info--image > div.active .person-info--image-container {
  width: 70vmin;
  height: 70vmin;
  max-width: 360px;
  max-height: 360px;
  top: calc(50% - 35vmin);
  left: calc(50% - 35vmin);
  transition: all 0.3s ease-in;
}
@media (min-width: 520px) {
  .person-info--image > div.active .person-info--image-container {
    top: calc(50% - 180px);
    left: calc(50% - 180px);
  }
}
.person-info--image > div :active,
.person-info--image > div :focus {
  outline: none;
}
.person-info--details {
  width: calc(100% - 100px);
  padding-right: 15px;
  box-sizing: border-box;
}
.person-info--details h2 {
  margin: 0;
  line-height: 1.25;
  font-size: 1.25em;
  font-weight: 500;
}
.person-info--details h2.company-name {
  font-weight: 300;
  display: inline-block;
}
.person-info--details .person-info--name {
  margin: 0;
  margin-bottom: 6px;
  font-size: 1.45em;
  line-height: 1;
  display: block;
}

.person-info .action {
  position: absolute;
  top: 35px;
  right: 10px;
  width: 26px;
  height: 26px;
  z-index: 100;
}

.person-info .action .svg-inline--fa {
  width: 100%;
  height: 100%;
}

.person-info .ellipsis-dropdown {
  position: absolute;
  top: calc(100% + 12px);
  right: 0;
  width: 125px;
  z-index: 100;
}

.person-info .ellipsis-dropdown.hidden {
  display: none;
}

.person-info .ellipsis-dropdown .ellipsis-dropdown-panel {
  background: var(--white);
  border: 1px solid var(--border);
  border-radius: 3px;
  box-shadow: 0px -1px 12px 3px rgba(0, 0, 0, 0.15);
  font-size: 1.25em;
  transition: all 0.5s ease-in-out;
}

.person-info .ellipsis-dropdown .ellipsis-dropdown-panel:after,
.person-info .ellipsis-dropdown .ellipsis-dropdown-panel:before {
  content: "";
  display: block;
  width: 0;
  height: 0;
  position: absolute;
}

.person-info .ellipsis-dropdown .ellipsis-dropdown-panel:after {
  right: 8px;
  top: -4px;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 5px solid var(--white);
}

.person-info .ellipsis-dropdown .ellipsis-dropdown-panel:before {
  right: 7px;
  top: -5px;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-bottom: 6px solid var(--border);
}

.person-info .ellipsis-dropdown .ellipsis-dropdown-panel .ellipsis-dropdown-item {
  display: block;
  width: 100%;
  padding: 12px 15px;
  box-sizing: border-box;
}
.appointment-media {
  padding: 10px 15px;
  width: calc(100% - 30px);
}
.appointment-media .title {
  width: 100%;
  display: block;
  margin: 0;
  margin-bottom: 10px;
}
.appointment-media .attachments {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  align-items: center;
  justify-items: center;
  justify-content: center;
}
.attachments .file-item {
  margin: 3px;
}

.meet-info {
  width: 100%;
  display: block;
  color: var(--black);
  text-decoration: none;
  height: calc((100vmin / 16) * 10);
  position: relative;
  border-bottom: 1px solid var(--border);
}
.meet-info:visited,
.meet-info:focus,
.meet-info:active {
  text-decoration: none;
  color: var(--black);
}
.meet-info--text-placeholder {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
}
.meet-info--image-placeholder {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9;
  filter: brightness(1.28);
  background-size: cover;
}
.location-info {
  position: absolute;
  padding: 10px 15px;
  top: 0;
  left: 0;
  display: block;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: calc(100% - 30px);
  background-image: var(--map-gradient);
}
.location-info .icon,
.location-info .status-icon {
  width: 40px;
  display: inline-block;
}
.status-icon .svg-inline--fa,
.icon .svg-inline--fa {
  display: block;
  width: 40px;
  height: 40px;
}
.status-icon .confirm {
  color: var(--success);
}
.status-icon .pending {
  color: var(--warning);
}
.status-icon .reject {
  color: var(--alert);
}
.location-info .info {
  width: calc(100% - 50px);
  display: inline-block;
  padding: 0 10px;
}
.info .text-info {
  margin: 0;
  padding: 0;
  font-weight: 500;
  letter-spacing: 1px;
  word-break: break-word;
  width: 100%;
}
.time-info {
  position: absolute;
  bottom: 0;
  left: 0;
  width: calc(100% - 30px);
  display: flex;
  justify-content: space-around;
  align-items: flex-end;
  flex-wrap: nowrap;
  padding: 10px 15px;
  background-image: var(--map-gradient-reverse);
}
.time-info .travel-time {
  width: 24%;
  display: inline-block;
  text-align: right;
  font-size: 1.25em;
}
.time-info .travel-time:last-child {
  text-align: left;
}

.time-info .meet-time {
  width: 52%;
  display: inline-block;
  text-align: center;
}
.meet-time h4 {
  width: 92%;
  font-size: 3em;
  font-weight: 700;
  letter-spacing: 3px;
  margin: 0 auto;
}
.meet-time h5 {
  font-size: 1em;
  margin: 0;
}
.meet-info .container {
  position: relative;
  height: 1.25em;
  width: 92%;
  margin: 0 auto;
}
.meet-time .line {
  position: absolute;
  background-image: var(--map-line);
  height: 2px;
  width: 100%;
  border-radius: 100%;
  margin: calc(1.25em / 2 - 1px) 0;
}
.meet-time .dot {
  position: absolute;
  left: calc(50% - 5px);
  width: 10px;
  height: 10px;
  background-color: var(--black);
  border-radius: 100%;
  margin: calc(1.25em / 2 - 5px) 0;
}

.appointment-info {
  padding: 10px 15px;
  /* margin-bottom: 46px; */
  z-index: 4;
  color: var(--black);
  background-color: var(--white);
}
.appointment-info > div {
  white-space: pre-line;
  padding: 0;
  margin: 0;
}
.appointment-info > div p {
  padding: 0;
  margin: 0;
}

main {
  overflow-y: auto;
  overscroll-behavior-y: auto;
  padding: 0;
  margin: 0;
  height: calc(100vh - 127px);
  position: relative;
  z-index: 2;
  background-color: var(--white);
  transition: all 3000ms ease-in-out;
}
footer {
  position: -webkit-sticky;
  position: sticky;
  bottom: 0;
  left: 0;
  height: 46px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap;
  background-color: var(--white);
  border-top: 1px solid var(--border);
  z-index: 5;
}
.new-file-upload {
  position: absolute;
  bottom: 46px;
  left: 0;
  width: 100%;
  box-sizing: border-box;
  padding: 0 15px 10px 15px;
}

.appointment-enter {
  opacity: 0.01;
  transform: translateX(-100%);
}

.appointment-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: all 3000ms ease-in-out;
}

.appointment-exit {
  opacity: 1;
  transform: translateX(0);
}

.appointment-exit-active {
  opacity: 0.01;
  transform: translateX(100%);
  transition: all 3000ms ease-in-out;
}

.slide-up-enter {
  opacity: 0;
  transform: translateY(-100%);
}

.slide-up-enter-active {
  opacity: 1;
  transform: translateY(0);
  transition: all 500ms ease-in-out;
}

.slide-up-exit {
  opacity: 1;
  transform: translateY(0);
}

.slide-up-exit-active {
  opacity: 0;
  transform: translateY(-100%);
  transition: all 500ms ease-in-out;
}

.list-view.edit {
  height: calc(100vh - 134px);
}
body {
  margin: 0;
  padding: 0;
  height: 100%;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  overscroll-behavior: none;
}

#root {
  --alert: #ef473a;
  --warning: #ffb75e;
  --success: #56ab2f;
  --main: #4484cb;
  --main-hover: #4484f7;
  --white: #fff;
  --black: #000;
  --gray: rgb(192, 186, 186);
  --border: #eceff1;
  --background-modal: #00000050;
  --background-placeholder: #eee;
  --background-card: #f3f3f3;
  --list-view-primary: #939faa37;
  --list-view-secondary: #eef2f3;
  --alert-gradient: linear-gradient(
    38deg,
    var(--alert),
    var(--background-card) 24% 76%,
    var(--alert)
  );
  --warning-gradient: linear-gradient(
    38deg,
    var(--warning),
    var(--background-card) 24% 76%,
    var(--warning)
  );
  --success-gradient: linear-gradient(
    38deg,
    var(--success),
    var(--background-card) 24% 76%,
    var(--success)
  );
  --page-gradient: linear-gradient(to bottom, var(--white), var(--list-view-secondary));
  --map-gradient: linear-gradient(rgba(255, 255, 255, 0.95) 60%, rgba(255, 255, 255, 0));
  --map-gradient-reverse: linear-gradient(
    to top,
    rgba(255, 255, 255, 0.95) 60%,
    rgba(255, 255, 255, 0)
  );
  --map-line: radial-gradient(
    circle at center,
    rgba(0, 0, 0, 1) 0%,
    rgba(0, 0, 0, 1) 80%,
    rgba(255, 255, 255, 0) 100%
  );
}

#root {
  max-width: 520px;
  margin: 0 auto;
  position: relative;
}

@media (min-width: 521px) {
  #root {
    border-left: 1px solid var(--border);
    border-right: 1px solid var(--border);
  }
}

