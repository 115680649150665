.preloader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: 150;
  background-color: rgba(0, 0, 0, 0.3);
}
.preloader .spinner {
  animation: rotate 2s linear infinite;
  z-index: 2;
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -50px 0 0 -50px;
  width: 100px;
  height: 100px;
}
.preloader .spinner .path {
  stroke: #4484cb;
  stroke-linecap: round;
  animation: dash 1.5s ease-in-out infinite;
}
