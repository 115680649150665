.page {
  overflow-y: auto;
  overscroll-behavior-y: auto;
  width: 100%;
  height: 100%;
  position: relative;
  box-sizing: border-box;
  background: var(--list-view-primary);
  background-image: var(--page-gradient);
  transition: opacity, transform, background-color 300ms linear;
}
section {
  position: relative;
  z-index: 2;
  transition: all 300ms ease-out;
  background-color: var(--list-view-primary);
  background-image: var(--page-gradient);
}
footer {
  position: sticky;
  bottom: 0;
  left: 0;
  height: 44px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap;
  background-color: var(--white);
  border-top: 1px solid var(--border);
  z-index: 5;
}
.page-enter {
  opacity: 0;
  transform: translateX(-100%);
}
.page-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: opacity, transform 300ms linear;
}
.page-exit {
  opacity: 1;
  transform: translateX(-100%);
}
.page-exit-active {
  opacity: 0;
  transform: translateX(0);
  transition: opacity, transform 300ms linear;
}
