.meet-info {
  width: 100%;
  display: block;
  color: var(--black);
  text-decoration: none;
  height: calc((100vmin / 16) * 10);
  position: relative;
  border-bottom: 1px solid var(--border);
}
.meet-info:visited,
.meet-info:focus,
.meet-info:active {
  text-decoration: none;
  color: var(--black);
}
.meet-info--text-placeholder {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
}
.meet-info--image-placeholder {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9;
  filter: brightness(1.28);
  background-size: cover;
}
.location-info {
  position: absolute;
  padding: 10px 15px;
  top: 0;
  left: 0;
  display: block;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: calc(100% - 30px);
  background-image: var(--map-gradient);
}
.location-info .icon,
.location-info .status-icon {
  width: 40px;
  display: inline-block;
}
.status-icon .svg-inline--fa,
.icon .svg-inline--fa {
  display: block;
  width: 40px;
  height: 40px;
}
.status-icon .confirm {
  color: var(--success);
}
.status-icon .pending {
  color: var(--warning);
}
.status-icon .reject {
  color: var(--alert);
}
.location-info .info {
  width: calc(100% - 50px);
  display: inline-block;
  padding: 0 10px;
}
.info .text-info {
  margin: 0;
  padding: 0;
  font-weight: 500;
  letter-spacing: 1px;
  word-break: break-word;
  width: 100%;
}
.time-info {
  position: absolute;
  bottom: 0;
  left: 0;
  width: calc(100% - 30px);
  display: flex;
  justify-content: space-around;
  align-items: flex-end;
  flex-wrap: nowrap;
  padding: 10px 15px;
  background-image: var(--map-gradient-reverse);
}
.time-info .travel-time {
  width: 24%;
  display: inline-block;
  text-align: right;
  font-size: 1.25em;
}
.time-info .travel-time:last-child {
  text-align: left;
}

.time-info .meet-time {
  width: 52%;
  display: inline-block;
  text-align: center;
}
.meet-time h4 {
  width: 92%;
  font-size: 3em;
  font-weight: 700;
  letter-spacing: 3px;
  margin: 0 auto;
}
.meet-time h5 {
  font-size: 1em;
  margin: 0;
}
.meet-info .container {
  position: relative;
  height: 1.25em;
  width: 92%;
  margin: 0 auto;
}
.meet-time .line {
  position: absolute;
  background-image: var(--map-line);
  height: 2px;
  width: 100%;
  border-radius: 100%;
  margin: calc(1.25em / 2 - 1px) 0;
}
.meet-time .dot {
  position: absolute;
  left: calc(50% - 5px);
  width: 10px;
  height: 10px;
  background-color: var(--black);
  border-radius: 100%;
  margin: calc(1.25em / 2 - 5px) 0;
}
