.list {
  padding: 10px 15px;
  margin: 0;
  overflow-y: auto;
  height: calc(100% - 20px);
  background: var(--list-view-primary);
  background: var(--page-gradient);
  scroll-behavior: smooth;
  scroll-snap-type: y mandatory;
}

.list li {
  width: 100%;
  list-style: none;
}

.list li:not(:last-child) {
  margin-bottom: 10px;
}

.btn-add {
  text-align: center;
}

.btn-add:not(:last-child) {
  margin-bottom: 10px;
}
