.icon-link {
  flex-grow: 1;
  width: 100%;
  height: 100%;
  /* max-width: 60px; */
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--black);
  background-color: var(--white);
  border: none;
  outline: none;
}
.icon-link:not(:first-child).logo {
  border: none;
  max-width: 60px;
}
.icon-link .logo {
  box-sizing: border-box;
  padding: 2px;
  width: 60%;
}
.icon-link.file-picker-label {
  position: relative;
}
.file-picker {
  position: absolute;
  opacity: 0;
  width: 0.1px;
  height: 0.1px;
}
.icon-link:visited,
.icon-link:focus,
.icon-link:active {
  text-decoration: none;
  color: var(--black);
}
.icon-link:not(:first-child) {
  border-left: 1px solid var(--border);
}
.icon-link svg.svg-inline--fa {
  width: 22px;
  height: 22px;
  color: var(--black);
}
.icon-link.disabled svg.svg-inline--fa {
  color: var(--background-placeholder);
}
.icon-link.large svg.svg-inline--fa {
  width: 30px;
  height: 30px;
}
.icon-link.small svg.svg-inline--fa {
  width: 16px;
  height: 16px;
}
.icon-link .fa-phone {
  transform: scale(-1, 1);
}
