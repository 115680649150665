body {
  margin: 0;
  padding: 0;
  height: 100%;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  overscroll-behavior: none;
}

#root {
  --alert: #ef473a;
  --warning: #ffb75e;
  --success: #56ab2f;
  --main: #4484cb;
  --main-hover: #4484f7;
  --white: #fff;
  --black: #000;
  --gray: rgb(192, 186, 186);
  --border: #eceff1;
  --background-modal: #00000050;
  --background-placeholder: #eee;
  --background-card: #f3f3f3;
  --list-view-primary: #939faa37;
  --list-view-secondary: #eef2f3;
  --alert-gradient: linear-gradient(
    38deg,
    var(--alert),
    var(--background-card) 24% 76%,
    var(--alert)
  );
  --warning-gradient: linear-gradient(
    38deg,
    var(--warning),
    var(--background-card) 24% 76%,
    var(--warning)
  );
  --success-gradient: linear-gradient(
    38deg,
    var(--success),
    var(--background-card) 24% 76%,
    var(--success)
  );
  --page-gradient: linear-gradient(to bottom, var(--white), var(--list-view-secondary));
  --map-gradient: linear-gradient(rgba(255, 255, 255, 0.95) 60%, rgba(255, 255, 255, 0));
  --map-gradient-reverse: linear-gradient(
    to top,
    rgba(255, 255, 255, 0.95) 60%,
    rgba(255, 255, 255, 0)
  );
  --map-line: radial-gradient(
    circle at center,
    rgba(0, 0, 0, 1) 0%,
    rgba(0, 0, 0, 1) 80%,
    rgba(255, 255, 255, 0) 100%
  );
}

#root {
  max-width: 520px;
  margin: 0 auto;
  position: relative;
}

@media (min-width: 521px) {
  #root {
    border-left: 1px solid var(--border);
    border-right: 1px solid var(--border);
  }
}
