.roadshow-item {
  padding: 10px;
  width: 100%;
  border-radius: 5px;
  height: 110px;
  position: relative;
  box-sizing: border-box;
  display: block;
  text-decoration: none;
}
.roadshow-item--image {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 5px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  z-index: 1;
}
.roadshow-item--title-placeholder {
  width: 100%;
  height: 100%;
  z-index: 2;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.roadshow-item--title {
  margin: 0;
  margin-right: auto;
  padding: 2px 6px;
  z-index: 2;
  line-height: 1.5;
  font-size: 1.1em;
  color: var(--black);
  display: inline-block;
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 5px;
}
.roadshow-item--title:not(:last-child) {
  margin-bottom: 5px;
}
