.login-page {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding: 0 5%;
  box-sizing: border-box;
}
.login-page--logo {
  width: 45%;
  max-width: 200px;
  margin: 0 auto;
}
.social-container {
  width: 100%;
}
.social-container .social-login {
  width: 100%;
  margin: 0 auto 12px;
  background-color: var(--background-placeholder);
  padding: 8px 16px;
  border: none;
  transition: all 0.5s ease-in-out;
  color: var(--primary);
  border-radius: 3px;
  display: inline-flex;
  justify-content: flex-start;
  align-items: center;
}
.social-container .social-login:last-child {
  margin-bottom: 0;
}
.social-login > span {
  font-size: 16px;
  line-height: 1.5;
  padding: 0;
}
.social-login .fa-google {
  color: var(--white);
}
.social-login .fa-linkedin {
  color: #0077b5;
}
.social-login .svg-inline--fa {
  width: 32px;
  height: 32px;
  margin-right: 10px;
}
