main {
  overflow-y: auto;
  overscroll-behavior-y: auto;
  padding: 0;
  margin: 0;
  height: calc(100vh - 127px);
  position: relative;
  z-index: 2;
  background-color: var(--white);
  transition: all 3000ms ease-in-out;
}
footer {
  position: sticky;
  bottom: 0;
  left: 0;
  height: 46px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap;
  background-color: var(--white);
  border-top: 1px solid var(--border);
  z-index: 5;
}
.new-file-upload {
  position: absolute;
  bottom: 46px;
  left: 0;
  width: 100%;
  box-sizing: border-box;
  padding: 0 15px 10px 15px;
}

.appointment-enter {
  opacity: 0.01;
  transform: translateX(-100%);
}

.appointment-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: all 3000ms ease-in-out;
}

.appointment-exit {
  opacity: 1;
  transform: translateX(0);
}

.appointment-exit-active {
  opacity: 0.01;
  transform: translateX(100%);
  transition: all 3000ms ease-in-out;
}

.slide-up-enter {
  opacity: 0;
  transform: translateY(-100%);
}

.slide-up-enter-active {
  opacity: 1;
  transform: translateY(0);
  transition: all 500ms ease-in-out;
}

.slide-up-exit {
  opacity: 1;
  transform: translateY(0);
}

.slide-up-exit-active {
  opacity: 0;
  transform: translateY(-100%);
  transition: all 500ms ease-in-out;
}

.list-view.edit {
  height: calc(100vh - 134px);
}