.file {
  --file-size: 60px;
  height: var(--file-size);
  width: 100%;
  position: relative;
  color: var(--black);
  border-radius: 4px;
  border: 1px solid var(--border);
  overflow: hidden;
  background-color: var(--border);
}
.file--container {
  height: 100%;
  width: 100%;
  position: absolute;
  z-index: 10;
  outline: none;
}
.file--wrapper,
.file--wrapper:visited {
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
  align-items: center;
  width: 100%;
  height: 100%;
  text-decoration: none;
  text-align: left;
  outline: none;
  color: var(--black);
  background-color: var(--white);
}
a.file--wrapper {
  cursor: pointer;
}
.file--view {
  display: block;
  width: 24%;
  height: var(--file-size);
  margin: 0;
  padding: 2px;
  box-sizing: border-box;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}
.file--view .svg-inline--fa {
  display: block;
  width: 100%;
  height: 100%;
}
.file--name {
  display: block;
  padding: 0 10px;
  box-sizing: border-box;
  width: calc(100% - 24% - 10px);
  margin: 0;
  word-break: break-word;
  user-select: none;
}
.file--controls {
  position: absolute;
  top: 0;
  height: var(--file-size);
  width: var(--file-size);
  padding: 12px 0;
  box-sizing: border-box;
  cursor: pointer;
  z-index: 9;
  color: var(--black);
  outline: none;
}
.file--controls.delete {
  right: 5px;
}
.file--controls.upload {
  left: 5px;
}
.file--controls .svg-inline--fa {
  width: 100%;
  height: 100%;
}
