.edit-appointment {
  text-align: center;
  position: relative;
  height: 100%;
}
.edit-fields h4 {
  margin: 0;
  padding: 12px 0;
}
.edit-fields input {
  width: calc(100% - 36px);
  margin: 0 auto;
  border: 2px solid var(--list-view-primary);
  padding: 12px 16px;
  font-size: 16px;
  line-height: 1.5;
  outline: none;
  -webkit-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  border-radius: 3px;
}
.rc-time-picker {
  width: 100%;
}
.rc-time-picker-input {
  width: 100% !important;
  height: auto !important;
}
.rc-time-picker-clear {
  top: 14px;
  right: 10px;
}
.rc-time-picker-clear-icon:after {
  font-size: 20px;
}
.rc-time-picker-panel {
  width: calc(90% - 20px);
  max-width: calc(0.9 * 520px - 20px);
}
.rc-time-picker-panel-inner {
  width: 100%;
}
.rc-time-picker-panel-select {
  width: 50%;
  max-height: 96px;
}
.hide-modal {
  position: absolute;
  top: -5px;
  right: -5px;
  padding: 5px 10px;
  border: none;
  background: transparent;
}
