.edit-fields textarea {
  width: calc(100% - 36px);
  margin: 0 auto;
  border: 2px solid var(--list-view-primary);
  padding: 12px 16px;
  font-family: inherit;
  font-size: 16px;
  line-height: 1.5;
  outline: none;
  -webkit-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  border-radius: 3px;
}

.edit-fields.edit-fields-person input,
.edit-fields.edit-fields-person textarea {
  margin-bottom: 12px;
}

.edit-fields.edit-fields-person input:last-child,
.edit-fields.edit-fields-person textarea:last-child {
  margin-bottom: 0;
}
