.appointment-media {
  padding: 10px 15px;
  width: calc(100% - 30px);
}
.appointment-media .title {
  width: 100%;
  display: block;
  margin: 0;
  margin-bottom: 10px;
}
.appointment-media .attachments {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  align-items: center;
  justify-items: center;
  justify-content: center;
}
.attachments .file-item {
  margin: 3px;
}
